define('collections/promotions',[
'jquery',
'underscore',
'backbone',
'moment'
], function ($, _, Backbone, moment) {
    'use strict';

    var PromotionModel = Backbone.Model.extend({
        parse: function (response) {
            response.id = response.id;
            response.discount = response.discount;
            response.start = moment(response.start);
            response.finish = moment(response.finish);
            response.serviceId = response.serviceId;
            
            return response;
        },
    });

    var Collection = Backbone.Collection.extend({
        url: '/website/promotion',
        model: PromotionModel,
        comparator: function(item) { return item.get('name'); }
    });

    return Collection;
});
