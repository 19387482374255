
define("tpl!libs/../../templates/promotions.html", ["underscore"], function(_) { return function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 _.each(promotions, function(promotion) { 
__p+='\n    <div class="text-center"><br>\n        <h3 class="font-thin">'+
((__t=( promotion.promotion ))==null?'':__t)+
'</h3>\n    </div>\n';
 }) 
__p+='\n';
}
return __p;
};});

