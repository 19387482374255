/*global require*/
require.config({
    paths: {
        jquery: 'libs/jquery-1.11.3.min',
        underscore: 'libs/underscore-min',
        backbone: 'libs/backbone.1.2.1.min',
        moment: 'libs/moment.min',
        templates: '../templates',
        tpl: 'libs/underscore-tpl',
        text: 'libs/text'
    },
    shim: {
        underscore: {
            exports: '_'
        },
        backbone: {
            deps: ['underscore', 'jquery'],
            exports: 'Backbone'
        }
    }
});

require([
'jquery',
'./views/promotions',
], function ($, PromotionsView) {
    'use strict';

    $(function() {
        var win = $(window);
        var nav = $('.nav');
        var splash = $('#splash');
        var splashDiv = splash.find('.splashdiv');
        var headerImage = $('#header-image');
        var headerTitle = $('#header-title');
        var titleSpan = headerTitle.find('div');
        var filterSpacer = $('<div />', {
            'height': nav.outerHeight()
        });
        
        var fixNav = function(force) {
            var fixedTop = !nav.hasClass('nav-fixtop');
            var fixedBottom = !nav.hasClass('nav-fixbottom');
            var splashHeight = splash.height();
            var navHeight = nav.outerHeight();
            var winScrollTop = win.scrollTop();
            
            if ((force || fixedTop) && winScrollTop > (splashHeight - navHeight)) {
                // Scrolled DOWN past nav, nav now needs to sick at the top.
                padTop(true);
                nav.before(filterSpacer);
                nav.addClass('nav-fixtop');
                nav.removeClass('nav-fixbottom');
            } else if ((force || fixedBottom) && winScrollTop < (splashHeight - navHeight)) {
                // Scrolled UP past nav, nav now needs to sick at the bottom.
                nav.removeClass('nav-fixtop');
                filterSpacer.remove();
                nav.addClass('nav-fixbottom');
                padTop();
            }
        };
        
        var padTop = function(reset) {
            var padding = 0;
            if (!reset) {
                var splashHeight = splash.height();
                var splashDivHeight = splashDiv.outerHeight();
                var splashDivOffset = Math.ceil(splashDiv.offset().top);
                var navHeight = nav.outerHeight();
                var magicPaddingAdjustmentNumber = 4;
                padding = Math.floor(splashHeight - splashDivHeight - splashDivOffset - navHeight - magicPaddingAdjustmentNumber);
            }
            
            if (padding < 0) {padding = 0;}
            
            nav.css('margin-top', padding + 'px');
        };
        
        var centerDiv = function() {
            // resize
            var height = window.innerHeight;
            
            var minHeight = splashDiv.height() + nav.height();
            if (height < minHeight) {
                height = minHeight;
            }
        
            splash.height(height);
            
            var imageHeight = headerImage.height();
            // headerTitle.css('height', imageHeight);
            var titleMargin = Math.floor((imageHeight - titleSpan.outerHeight()) / 2);
            titleSpan.css('margin-top', titleMargin + 'px');
            
            var padding = ((height - splashDiv.height()) / 2) - nav.height();
            
            if (padding < 0) {padding = 0;}
            
            var paddingTop = padding + 'px';

            splashDiv.css('margin-top', paddingTop);
            splashDiv.css('opacity', 1);
        };
        
        var initialScroll = function() {
            var hash  = window.location.hash;
            if (hash.length > 0) {
                var dest = $(hash);
                if (dest.length === 0) {
                    return;
                }
                var scrollTarget = dest.offset().top - $('.nav').outerHeight();
                
                //go to destination
                $('html,body').animate({
                    scrollTop: scrollTarget
                }, 250, 'swing');

            }    
        };
        
        centerDiv();
        fixNav(true);
        
        win.scroll(function() {
            fixNav(false);
        });
        win.resize(function() {
            centerDiv();
            padTop();
            fixNav(true);
        });
        
        nav.css('opacity', 1);
        new PromotionsView({el: '#promotion', mobileEl: '#promotionMobile'});
        //new ServicesView({el: '#services-table'});
        initialScroll();
        
        if (location.search.indexOf('gclid=') > -1) {
            document.cookie='ref=cpc';
        }    
    });

    $('.scroll').click(function (event) {
        event.preventDefault();
        //calculate destination place
        var dest = 0;
        if ($(this.hash).offset().top > $(document).height() - $(window).height()) {
            dest = $(document).height() - $(window).height();
        } else {
            dest = $(this.hash).offset().top - $('.nav').outerHeight();
        }
        //go to destination
        $('html,body').animate({
            scrollTop: dest
        }, 750, 'swing');
    });

});
define("mainpage", function(){});

