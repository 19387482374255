define('views/promotions',[
'jquery',
'underscore',
'backbone',
'../libs/promotionHelper'
], function ($, _, Backbone, Promotions) {
    'use strict';
    
    var View = Backbone.View.extend({
        initialize: function(options) {
            this.$mobEl = $(options.mobileEl);
            
            Promotions.promotions.on('reset', _.bind(function() { 
                this.render(); 
            }, this));
        },
        
        render: function() { 
            this.$el.html(Promotions.promotionTitle);
            this.$mobEl.html(Promotions.promotionTitle);
            
            return this;
        }
    });

    return View;
});
