define('libs/promotionHelper',[
'jquery',
'underscore',
'backbone',
'../collections/promotions',
'tpl!../../templates/promotions.html',
'tpl!../../templates/promotiontitle.html'
], function ($, _, Backbone, PromotionsCollection, template, titleTemplate) {
    'use strict';

    var helper = {
        promotionsLoaded: false,
        promotions: new PromotionsCollection(),
        setTime: function(time, selectedServices) { 
            if (_.isUndefined(time)) {
                helper.promotionApplies = false;
                return;
            }
            
            var promotion = helper.promotions.find(function(promo) {
                if (promo.get('start').isAfter(time)) { 
                    return false; 
                }
                if (promo.get('finish').isBefore(time)) {
                    return false; 
                }
                
                if (!isNaN(promo.get('serviceId'))) {
                    var service = selectedServices.collection.findWhere({ id: promo.get('serviceId') });
                    if (_.isUndefined(service)) { 
                        return false; 
                    }
                }
                
                switch (time.day()) {
                    case 0:
                        return false;
                    case 1:
                        return promo.get('mon');
                    case 2:
                        return promo.get('tue');
                    case 3:
                        return promo.get('wed');
                    case 4:
                        return promo.get('thu');
                    case 5:
                        return promo.get('fri');
                    case 6:
                        return promo.get('sat');
                }
            });
            
            if (_.isUndefined(promotion)) {
                helper.promotionApplies = false;
                return;
            }
            
            helper.promotionApplies = promotion.toJSON();
        },
        promotionHtml: '',
        promotionApplies: false,
    };

    helper.promotions.on('reset', function() {
        helper.promotionHtml = template({_: _, promotions: helper.promotions.toJSON()});
        helper.promotionTitle = titleTemplate({_: _, promotions: helper.promotions.toJSON()});
        helper.promotionsLoaded = true;
    });
                
    helper.promotions.fetch({ reset: true });

    return helper;
});

