
define("tpl!libs/../../templates/promotiontitle.html", ["underscore"], function(_) { return function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 _.each(promotions, function(promotion) { 
__p+='\n'+
((__t=( promotion.promotion ))==null?'':__t)+
'\n';
 }) 
__p+='\n';
}
return __p;
};});

